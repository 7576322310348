import PropTypes from "prop-types";
import React, { useEffect } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { siteinfos } from "../../../cms/data/settings";
import styled from "styled-components";
const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 400px;
  #contact-map-wrapper {
    width: 100%;
    height: 100%;
    #contact-map {
      position: relative;
      width: 100%;
      height: 100%;
      & > div {height: 100%;}
    }
  }
  .mapboxgl-control-container {
    display: none;
  }
`;

const Map = ({ position }) => {
  console.log(position);
  position = JSON.parse(position);
  const mapSettings = {
    apiKey: siteinfos.mapboxtoken,
    map: {
      container: "contact-map",
      center: [166.445217,-22.234541],
      offset: [0, 0], //h,v
      style: "mapbox://styles/mapbox/streets-v11",
      zoom: 16,
      scrollZoom: false,
      interactive: true
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      const mapboxgl = require("mapbox-gl");

      mapboxgl.accessToken = mapSettings.apiKey;
      const map = new mapboxgl.Map(mapSettings.map);
      map.flyTo({
        center: mapSettings.map.center,
        zoom: mapSettings.map.zoom,
        offset: window.innerWidth >= 10 ? mapSettings.map.offset : [0, 0],
        maxDuration: 1
      });
      new mapboxgl.Marker({
        color: "#db7c26"
      })
        .setLngLat(position.coordinates)
        .addTo(map);
      window.onresize = function() {
        map.flyTo({
          center: mapSettings.map.center,
          zoom: mapSettings.map.zoom,
          offset: window.innerWidth >= 10 ? mapSettings.map.offset : [0, 0],
          maxDuration: 1
        });
      };
    }
  }, []);
  return (
    <MapWrapper>
      <div id="contact-map-wrapper">
        <div id="contact-map" />
      </div>
    </MapWrapper>
  );
};

Map.propTypes = {
  position: PropTypes.any
};

export default Map;
