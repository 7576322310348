import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Forms from "../../Forms";

const Form = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Forms data={data.form.frontmatter} />
    </Wrapper>
  );
};

Form.propTypes = {
  data: PropTypes.shape({
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    })
  })
};
export default Form;

export const query = graphql`
  fragment FormBlock on MarkdownRemarkFrontmatterBlockscollection {
    form {
      frontmatter {
        title
        formId
        intro
        successMessage
        elements {
          imputType
          label
          name
          placeholder
          required
          type
          default
          multiple
          options {
            value
            label
          }
          maxFiles
          maxSizeBytes
        }
      }
    }
  }
`;
