import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import { Box, Text } from "rebass/styled-components";
import Markdown from "../../UI/Markdown"
const Markup = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Grid childWidth={[1, 1 / 2, 1 / 3]}>
        {data.numberItem.map((item, i) => (
          <Box key={"num-" + i} textAlign="center" color="white">
            <Text>{item.number}</Text>
            <Text>{item.exposant}</Text>
            <Markdown>{item.content}</Markdown>
          </Box>
        ))}
      </Grid>
    </Wrapper>
  );
};
export default Markup;

export const query = graphql`
  fragment NumbersBlock on MarkdownRemarkFrontmatterBlockscollection {
    numberItem {
      number
      content
      exposant
    }
  }
`;
