import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import { Box, Heading, Flex } from "rebass/styled-components";

const Cards = ({ data }) => {
  return (
    <Wrapper data={data}>
      {data.cards.map((card, i) => (
        <Box key={"card-" + i} py={4}>
          <Flex variant="grid" childWidth={[1, 1 / 2]} alignItems="center">
            {card.image && (
              <>
                <Box variant="gridItem" width={[1, 1 / 3]} py={4}>
                  <CustomImage img={card.image} />
                </Box>
                <Box variant="gridItem" width={[1, 2 / 3]}>
                  {card.title && <Heading as="h2">{card.title}</Heading>}
                  {card.content && <Markdown>{card.content}</Markdown>}
                </Box>
              </>
            )}
            {!card.image && (
              <Box variant="gridItem" width={[1]}>
                {card.title && <Heading as="h2">{card.title}</Heading>}
                {card.content && <Markdown>{card.content}</Markdown>}
              </Box>
            )}
          </Flex>
        </Box>
      ))}
    </Wrapper>
  );
};

Cards.propTypes = {
  data: PropTypes.shape({
    cards: PropTypes.array
  })
};
export default Cards;

export const query = graphql`
  fragment CardsBlock on MarkdownRemarkFrontmatterBlockscollection {
    cards {
      content
      title
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 400, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    style
  }
`;
