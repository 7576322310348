import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Map from "./Map";
import Forms from "../../Forms";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import Markdown from "../../UI/Markdown";
import Fontello from "../../UI/Fontello";
import CustomImage from "../../UI/CustomImage";
import Grid from "../../UI/Grid";
import Wrapper from "../Wrapper";

const FormMap = ({ data }) => {
  return (  
    <Wrapper
    data={data} 
    p={[0,0,0,0,0]}
    sx={{
      position: "relative",
      bg: "primary",
      "svg": {
        fill: "white",
        color: "white"
      }
    }}>
    <Grid 
    childWidth={[1, 1, 1, 1 / 2]} 
    sx={{
      "& > div:last-child": {
        p:0
      }
    }}>
      <Box pt={[10, 11, 12, 13]} pb={[6, 6, 6, 4]} pr={[0,0,0,10,12]} color="white">
        {data.titleBlock && (
          <Heading as="h2" color="white" sx={{borderColor: "primaryLight"}}>{data.titleBlock}</Heading>
        )}
        <Forms data={data.form.frontmatter} />
      </Box>
      <Box 
      sx={{
        position: ["relative","relative","relative","absolute"],
        top: 0,
        left: [0,0,0,"50%"],
        height: "100%",
        width: ["100%","100%","100%","50%"]
      }}>
        <Box sx={{ position: "relative" }} height="100%" maxHeight="100%">
          <Map position={data.position} />
          {data.showInfo && (
            <Box
            backgroundColor="white"
            color="primary"
            sx={{
              position: "absolute",
              bottom: 6,
              left: 6,
              boxShadow: "strong"
            }}
            p={4}
            lineHeight="1.33"
          >            
            <CustomImage img={data.logoFormMap} alt="Logo Helisud" mb={2} />
            {data.cardTitle && (
              <Heading as="h3" variant="map">
                {data.cardTitle}
              </Heading>
            )}
            <Flex>
              <Fontello icon="location" className="icon icon-secondary" mt={4} />
              <Markdown
              sx={{
                "p": {
                  marginTop: 0,
                  marginBottom: 3
                }
              }}>{data.address}</Markdown>
            </Flex>
            <Text marginBottom={3}><Fontello icon="phone" className="icon icon-secondary" />{data.phone}</Text>
            <Text><Fontello icon="mail" className="icon icon-secondary" />{data.email}</Text>
          </Box>
          )}
        </Box>
      </Box>
    </Grid>
    </Wrapper>
  );
};

FormMap.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.any,
    cardTitle: PropTypes.any,
    titleBlock: PropTypes.any,
    email: PropTypes.any,
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    }),
    phone: PropTypes.any,
    position: PropTypes.any,
    showInfo: PropTypes.any,
    logoFormMap: PropTypes.any
  })
};

export default FormMap;

export const query = graphql`
  fragment FormMapBlock on MarkdownRemarkFrontmatterBlockscollection {
    titleBlock
    form {
      frontmatter {
        title
        formId
        intro
        successMessage
        elements {
          width
          imputType
          placeholder
          label
          name
          required
          type
          default
          multiple
          options {
            value
            label
          }
          maxFiles
          maxSizeBytes
        }
      }
    }
    position
    address
    cardTitle
    phone
    email
    showInfo
    logoFormMap {
      publicURL
      childImageSharp {
        fixed(height: 50, quality: 64) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;
