import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { withTheme } from "styled-components";
import Markdown from "../UI/Markdown";
import { Label, Input, Select, Textarea, Radio, Checkbox } from "@rebass/forms/styled-components";
import Dropzone from "react-dropzone-uploader";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import Fontello from "../UI/Fontello";
import ReCAPTCHA from "react-google-recaptcha";
import JSZip from "jszip";

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
const encodeData = data => {
  const formData = new FormData();

  Object.keys(data).forEach(key => formData.append(key, data[key]));

  return formData;
};
const isValidEmail = email => {
  var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return email.search(emailRegEx) !== -1;
};
const Form = ({ data }) => {
  const [messages, setMessages] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [hasError, setHasError] = useState(false);
  const [sent, setSent] = useState(false);

  if (!data) {
    return (
      <Box>
        <Text>Formulaire Non disponible en previsualisation</Text>
      </Box>
    );
  }
  data = data || { formId: "", elements: [], successMessage: "" };
  const handleChange = (valueName, value) =>
    setFormValues({
      ...formValues,
      [valueName]: value
    });
  let captchaRef = useRef(null);

  const handleSubmit = e => {
    e.preventDefault();
    const newMessages = [];
    var hasTempError = false;
    const captchaValue = captchaRef.current.getValue();

    data.elements.map(function(formElement) {
      var currentValue = formValues[formElement.name] || "";
      if (formElement.required && currentValue === "" && formElement.type !== "checkbox") {
        hasTempError = true;
        newMessages.push('■ Le champ "' + formElement.placeholder + '" est requis.');
      }
      if (formElement.type === "text") {
        if (formElement.imputType === "email") {
          if (!isValidEmail(currentValue)) {
            hasTempError = true;
            newMessages.push("■ Email non valide.");
          }
        }
      }
      if (formElement.type === "checkbox") {
        if (formElement.name === "rgpd" || formElement.name === "cgv") {
          if (formElement.required && currentValue === "") {
            hasTempError = true;
            newMessages.push('■ Veuillez cocher la case "' + formElement.name + '"');
          }
        }
      }
      return "";
    });
    if (!captchaValue) {
      hasTempError = true;
      newMessages.push("■ Veuillez confirmer que n'êtes pas un robot");
    }
    setMessages(newMessages);
    setHasError(hasTempError);
    if (!hasTempError) {
      fetch("/", {
        method: "POST",
        // headers: { "Content-Type": "application/x-www-form-urlencoded" },
        // headers: { "Content-Type": "multipart/form-data" },
        body: encodeData({
          "form-name": data.formId,
          "g-recaptcha-response": captchaValue,
          ...formValues
        })
      })
        .then(() => submitSuccess())
        .catch(error => alert(error));
    }
  };
  const submitSuccess = () => {
    setFormValues({});
    setMessages([data.successMessage]);
    setSent(true);
    setTimeout(function() {
      setMessages([]);
      setSent(false);
    }, 3000);
  };
  // file
  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = (fileWithMeta, status, filesWithMeta, valueName) => {
    if (status === "done" || status === "removed") {
      var fileList = [];
      if (status === "done") {
        fileList = filesWithMeta.map(files => files.file);
        // setFormValues({
        //   ...formValues,
        //   [valueName]: filesWithMeta.map(files=>(files.file))[0]
        // });
      }
      if (status === "removed") {
        fileList = filesWithMeta
          .map(files => files.file)
          .filter(file => file.name !== fileWithMeta.file.name);
        // setFormValues({
        //   ...formValues,
        //   [valueName]: filesWithMeta.map(files=>(files.file)).filter(file=>file.name!==fileWithMeta.file.name)[0]
        // });
      }
      const zip = new JSZip();
      if (fileList.length > 0) {
        fileList.forEach(file => zip.file(file.name, file));

        zip.generateAsync({ type: "blob" }).then(blob => {
          const zippedFiles = new File([blob], "fichiers.zip", {
            lastModified: Date.now(),
            type: "application/zip"
          });
          setFormValues({
            ...formValues,
            [valueName]: zippedFiles
          });
        });
      } else {
        setFormValues({
          ...formValues,
          [valueName]: null
        });
      }
    }
  };
  return (
    <Box>
      {data.intro && <Markdown>{data.intro}</Markdown>}
      <form
        data-netlify="true"
        data-netlify-recaptcha="true"
        onSubmit={e => {
          handleSubmit(e);
        }}
        name={data.formId}
        id={data.formId}
      >
        <input type="hidden" name="form-name" aria-label="form-name" value={data.formId}></input>
        {!sent && (
          <Flex flexWrap="wrap" mx={-2}>
            {data.elements.map((formElement, i) => {
              formElement.options = formElement.options || [{ value: "" }];
              formElement.width = formElement.width || "100%";
              return (
                <Box
                  key={"form-el-" + i}
                  pb={5}
                  p={2}
                  width={["100%", formElement.width]}
                  className={formElement.type}
                >
                  {formElement.type === "text" && (
                    <>
                      {formElement.label && (
                        <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      )}
                      <Input
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        placeholder={
                          formElement.required
                            ? formElement.placeholder + "*"
                            : formElement.placeholder
                        }
                        onChange={e => {
                          handleChange(formElement.name, e.target.value);
                        }}
                      />
                    </>
                  )}
                  {formElement.type === "textarea" && (
                    <>
                      {formElement.label && (
                        <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      )}
                      <Textarea
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        placeholder={
                          formElement.required
                            ? formElement.placeholder + "*"
                            : formElement.placeholder
                        }
                        rows="6"
                        onChange={e => {
                          handleChange(formElement.name, e.target.value);
                        }}
                      />
                    </>
                  )}
                  {formElement.type === "radio" && (
                    <>
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      {formElement.options.map((option, iOption) => (
                        <Label key={iOption}>
                          <Radio
                            id={formElement.name + "-" + iOption}
                            name={formElement.name}
                            value={option.value}
                            onChange={e => {
                              handleChange(
                                formElement.name,
                                e.target.checked ? e.target.value : ""
                              );
                            }}
                            defaultChecked={option.value === formElement.default}
                          />
                          {option.label}
                        </Label>
                      ))}
                    </>
                  )}
                  {formElement.type === "checkbox" && (
                    <Box
                      sx={{
                        "input:checked ~ .checkit": {
                          display: "none"
                        },
                        "input:checked ~ .checkit:nth-child(2)": {
                          display: "block"
                        }
                      }}
                    >
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      {formElement.options.map((option, iOption) => (
                        <Label
                          key={iOption}
                          sx={{
                            "& > div": {
                              minWidth: "auto"
                            }
                          }}
                        >
                          <Checkbox
                            className="checkit"
                            id={formElement.name + "-" + iOption}
                            name={formElement.name}
                            value={option.value}
                            onChange={e => {
                              handleChange(
                                formElement.name,
                                e.target.checked ? e.target.value : ""
                              );
                            }}
                            defaultChecked={option.value === formElement.default}
                          />
                          <Box fontSize={0} pl={3}>
                            {option.label}
                          </Box>
                        </Label>
                      ))}
                    </Box>
                  )}
                  {formElement.type === "select" && (
                    <>
                      {formElement.label && (
                        <Label htmlFor={formElement.name}>
                          {formElement.required ? formElement.label + "*" : formElement.label}
                        </Label>
                      )}
                      <Select
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        onChange={e => {
                          handleChange(
                            formElement.name,
                            e.target.selectedOptions ? e.target.value : ""
                          );
                        }}
                      >
                        {formElement.options.map((optionObj, iOption) => (
                          <option key={iOption} value={optionObj.value}>
                            {optionObj.label}
                          </option>
                        ))}
                      </Select>
                    </>
                  )}
                  {formElement.type === "file" && (
                    <>
                      {formElement.label && (
                        <Label htmlFor={formElement.name}>
                          {formElement.required ? formElement.label + "*" : formElement.label}
                        </Label>
                      )}
                      <Box
                        sx={{
                          bg: "primaryLight",
                          borderStyle: "dashed",
                          borderWidth: "2px",
                          borderColor: "rgba(255,255,255,.3)",
                          ".dzu-input": { display: "none" },
                          ".dzu-inputLabel, .dzu-inputLabelWithFiles": {
                            py: 3,
                            px: 4,
                            display: "block",
                            cursor: "pointer"
                          },
                          ".dzu-inputLabelWithFiles": {
                            borderTopStyle: "dashed",
                            borderTopWidth: "2px",
                            borderTopColor: "rgba(255,255,255,.3)"
                          },
                          ".dzu-previewContainer": {
                            py: 3,
                            px: 4,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            borderTopStyle: "dashed",
                            borderTopWidth: "2px",
                            borderTopColor: "rgba(255,255,255,.3)",
                            "&:first-child": { border: "none" },
                            "& > *:first-child": {
                              maxWidth: "50%"
                            },
                            "& > img:first-child": {
                              maxWidth: "30%"
                            },
                            ".dzu-previewStatusContainer": {
                              display: "flex",
                              alignItems: "center"
                            },
                            ".dzu-previewButton": {
                              backgroundSize: "14px 14px",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              width: "14px",
                              height: "14px",
                              cursor: "pointer",
                              ml: 2
                            }
                          },
                          ".dzu-submitButtonContainer": { display: "none" }
                        }}
                      >
                        <input
                          name={formElement.name}
                          multiple=""
                          type="file"
                          autoComplete="off"
                          tabIndex="-1"
                          style={{ display: "none" }}
                        ></input>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={(fileWithMeta, status, filesWithMeta) =>
                            handleChangeStatus(
                              fileWithMeta,
                              status,
                              filesWithMeta,
                              formElement.name
                            )
                          }
                          maxFiles={formElement.maxFiles}
                          multiple={formElement.maxFiles > 1 ? true : false}
                          maxSizeBytes={formElement.maxSizeBytes * 1000000}
                          inputContent={formElement.placeholder}
                          inputWithFilesContent={formElement.placeholder}
                          submitButtonContent="Envoyer"
                          submitButtonDisabled={true}
                          name={formElement.name}
                        />
                      </Box>
                    </>
                  )}
                  {formElement.type === "submit" && (
                    <Box pb={3}>
                      <ReCAPTCHA
                        name="g-recaptcha-response"
                        ref={captchaRef}
                        sitekey="6LcQ4OAZAAAAALWgFlWrNQhC1BPm8pLha4CBzTFh"
                        onChange={val => {
                          setValue("g-recaptcha-response", val, true);
                        }}
                      />
                    </Box>
                  )}
                  {formElement.type === "submit" && (
                    <Button type="submit" variant="secondary">
                      {formElement.label}
                      <Fontello icon="arrow-right" />
                    </Button>
                  )}
                </Box>
              );
            })}
          </Flex>
        )}
      </form>
      {messages.length > 0 && (
        <Box
          p={3}
          color={hasError ? "white" : "white"}
          sx={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: hasError ? "danger" : "success",
            bg: hasError ? "danger" : "success"
          }}
        >
          {messages.map((message, index) => (
            <Text key={`contact-form-message-${index}`}>{message}</Text>
          ))}
        </Box>
      )}
    </Box>
  );
};

Form.propTypes = {
  data: PropTypes.shape({
    elements: PropTypes.array,
    formId: PropTypes.string,
    intro: PropTypes.any,
    successMessage: PropTypes.string
  })
};
export default withTheme(Form);
