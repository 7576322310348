import PropTypes from "prop-types";
import React, { useState } from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import Service from "./Service";
import { Box, Heading, Flex, Button } from "rebass/styled-components";

const Services = ({ data }) => {
  return (
    <Wrapper data={data}>
        {data.title && (
            <Heading as="h2">{data.title}</Heading>
        )}
        {data.intro && (
            <Markdown>{data.intro}</Markdown>
        )}
        <Flex variant="grid">
            {data.servicesList.map((service, i) => (
                <Service service={service} key={i} />
            ))}
        </Flex>
    </Wrapper>
  );
};

Services.propTypes = {
  data: PropTypes.shape({
    servicesList: PropTypes.array
  })
};
export default Services;

export const query = graphql`
  fragment ServicesBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    intro
    servicesList {
        name
        description
        modal {
            content
            image {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 600, quality: 64) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
  }
`;
