import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import CustomImage from "../../UI/CustomImage";
import { Box } from "rebass/styled-components";

const Gallery = ({ data }) => {
  const images = data.images || [];
  return (
    <Wrapper data={data}>
      <Grid childWidth={[1, 1 / 2, 1 / 3, 1 / 4]}>
        {images.map((image, i) => (
          <Box key={"img-" + i} pb={4}>
            <CustomImage img={image.image} />
          </Box>
        ))}
      </Grid>
    </Wrapper>
  );
};

Gallery.propTypes = {
  data: PropTypes.shape({
    images: PropTypes.array
  })
};
export default Gallery;
export const query = graphql`
  fragment GalleryBlock on MarkdownRemarkFrontmatterBlockscollection {
    images {
      alt
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 300, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
