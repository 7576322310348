import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import { graphql } from "gatsby";
import Icofont from "../../UI/Icofont";
import { Box, Heading, Text, Button } from "rebass/styled-components";
import CustomImage from "../../UI/CustomImage";
import Link from "../../UI/Link";

const HeroHeader = ({ data }) => {
  const bgImg = data.blockSettings
    ? data.blockSettings.bgImg
      ? data.blockSettings.bgImg
      : ""
    : "" || "";
  const bgColor = data.blockSettings
    ? data.blockSettings.bg
      ? data.blockSettings.bg
      : false
    : false || false;
  data.logo = data.logo || "";
  data.ctas = data.ctas || [];

  const sx = {
    backgroundImage: `url(${
      typeof bgImg === "string" ? bgImg : bgImg.childImageSharp.fluid.srcWebp
    })`,
    position: "relative",
    ">*": {
      position: "relative",
      zIndex: 1
    }
  };
  if (bgColor) {
    sx[":before"] = {
      content: '""',
      position: "absolute",
      zIndex: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%",
      bg: bgColor,
      opacity: 0.85
    };
  };
  return (
    <Wrapper
      data={data}
      sx={sx}
      containerProps={{ 
        display: "flex",
        alignItems: "center",
        justifyContent: ["center","center","left"] 
      }}
      height="100vh"
      display="flex"
    >
      <Box
      sx={{
        maxWidth: ["100%","100%","70%","55%"],
        pl: [3,3,4],
        borderLeftStyle: "solid",
        borderLeftColor: "primary",
        borderLeftWidth: "10px"
      }}>
        <CustomImage py={2} img={data.logo} alt={data.bigTitle} maxWidth="250px" mx="auto" />
        <Heading as="h1" variant="h1" sx={{lineHeight: "1"}}>
          {data.bigTitle}
        </Heading>
        {data.subTitle && (
          <Text color="white" fontSize={[5,5,6]}>
            {data.subTitle}
          </Text>
        )}
        {data.ctas.map((cta, i) => (
          <Link href={cta.href} key={i}>
            <Button>
              <Icofont icon={cta.icon} />
              {cta.label}
            </Button>
          </Link>
        ))}
      </Box>
    </Wrapper>
  );
};

HeroHeader.propTypes = {
  data: PropTypes.shape({
    bigTitle: PropTypes.any,
    blockSettings: PropTypes.shape({
      bg: PropTypes.any,
      bgImg: PropTypes.any
    }),
    ctas: PropTypes.array,
    logo: PropTypes.any,
    subTitle: PropTypes.any
  })
};

export default HeroHeader;

export const query = graphql`
  fragment HeroHeaderBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    logo {
      childImageSharp {
        fluid(maxHeight: 200, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    subTitle
    ctas {
      href
      icon
      label
    }
  }
`;
