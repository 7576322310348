import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import CustomImage from "../../UI/CustomImage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Wrapper from "../Wrapper";
import { Box, Heading } from "rebass/styled-components";

const Carousel = ({ data }) => {
  var settings = {
    dots: true,
    autoplaySpeed: 3000,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: data.carouselItems.length > 4 ? 4 : data.carouselItems.length,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: data.carouselItems.length > 3 ? 3 : data.carouselItems.length,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: data.carouselItems.length > 2 ? 2 : data.carouselItems.length,
          slidesToScroll: 2
        }
      }
    ]
  };
  const sx = {
    textAlign: "center",
    "ul.slick-dots": {
      position: "inherit", 
      bottom: "0",
      mt: 4,
      "li": {
        m: 0,
        "&:before": {
          content: "''",
        },
        "button:before": {
          content: "''",
          width: "8px",
          height: "8px",
          backgroundColor: "text",
          opacity: "1",
          transition: "all 300ms ease",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        },
        "&.slick-active": {
          "button:before": {
            width: "12px",
            height: "12px",
            backgroundColor: "primary",
          }
        }
      }
    }
  };
  return (
    <Wrapper data={data} sx={sx}>
      {data.title && (
        <Heading as="h2">{data.title}</Heading>
      )}
      <Slider {...settings}>
        {data.carouselItems.map((carouselItem, i) => (
          <Box px={[5,6,8,10]} py={[3,4,4,4]} key={"partenaire-" + i}>
            <Box>
              <CustomImage img={carouselItem.image} />
            </Box>
          </Box>
        ))}
      </Slider>
    </Wrapper>
  );
};

Carousel.propTypes = {
  data: PropTypes.shape({
    carouselItems: PropTypes.array
  })
};

Carousel.defaultProps = {
  data: {
    carouselItems: []
  }
};
export default Carousel;
export const query = graphql`
  fragment CarouselBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    carouselItems {
      name
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 300, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
