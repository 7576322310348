import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import { Box, Heading, Flex } from "rebass/styled-components";

const ContentTwoCol = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Flex
        variant="grid"
        alignItems="flex-start"
        flexDirection={
          data.textPosition === "left" && (
              ["column-reverse", "column-reverse", "row"]
          ),
          data.textPosition === "right" && (
              ["column-reverse", "column-reverse", "row-reverse"]
          )
      }
      >
        <Box width={[1, 1, 4/7]}>
          {data.title && (
            <Heading as="h2">{data.title}</Heading>
          )}
          <Markdown>{data.content}</Markdown>
        </Box>
        <CustomImage img={data.image} ml="auto" mr="auto" width={[1, 1, 3/7]} />
      </Flex>
    </Wrapper>
  );
};

ContentTwoCol.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.any,
    title: PropTypes.any,
    image: PropTypes.any,
    content: PropTypes.any,
    textPosition: PropTypes.any,
    blockSettings: PropTypes.shape({
      bgImg: PropTypes.any,
      variant: PropTypes.any
    }),
  })
};
export default ContentTwoCol;

export const query = graphql`
  fragment ContentTwoColBlock on MarkdownRemarkFrontmatterBlockscollection {
    type
    content
    title
    image {
      publicURL
      childImageSharp {
        fluid(maxWidth: 400, quality: 64) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctas {
      href
      label
      icon
    }
    textPosition
  }
`;
