import PropTypes from "prop-types";
import React, { useState } from "react";
import Markdown from "../../UI/Markdown";
import Fontello from "../../UI/Fontello";
import Modal from "../../UI/Modal";
import CustomImage from "../../UI/CustomImage";
import { Box, Heading, Flex, Button } from "rebass/styled-components";

const Service = ({ service }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = e => setIsOpen(!isOpen);
  return (
    <Box width={["100%", "100%", "50%"]} display="flex">
      <Box
        display="flex"
        flexDirection="column"
        p={[4, 5, 6]}
        sx={{
          bg: "grayLight"
        }}
      >
        <Heading as="h3" variant="h3">
          {service.name}
        </Heading>
        <Markdown
          sx={{
            flex: "1 0 auto",
            "& > p:first-child": {
              mt: 0
            }
          }}
        >
          {service.description}
        </Markdown>
        <Box pt={3}>
          <Button onClick={toggleModal}>
            En savoir plus
            <Fontello icon="arrow-right" />
          </Button>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
        <Heading as="h3" variant="titleModal">
          {service.name}
        </Heading>
        <Flex variant="grid" flexDirection={["column-reverse", "column-reverse", "row"]}>
          <Box width={["100%", "100%", 2 / 5]}>
            <CustomImage img={service.modal.image} alt={service.name} />
          </Box>
          <Box width={["100%", "100%", 3 / 5]}>
            <Markdown sx={{ "p:first-child": { mt: 0 } }}>{service.modal.content}</Markdown>
          </Box>
        </Flex>
        <Box pt={6} textAlign="center">
          <Button onClick={toggleModal}>
            Fermer la fenêtre
            <Fontello icon="close" />
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

Service.propTypes = {
  service: PropTypes.shape({
    description: PropTypes.any,
    modal: PropTypes.shape({
      content: PropTypes.any,
      image: PropTypes.any
    }),
    name: PropTypes.any
  })
};

export default Service;
