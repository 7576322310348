import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Flex, Box, Heading, Text, Button } from "rebass/styled-components";
import CustomImage from "../../UI/CustomImage";
import Markdown from "../../UI/Markdown";
import Link from "../../UI/Link";
import Fontello from "../../UI/Fontello";

const Features = ({ data }) => {
  data.items = data.items || [];
  return (
    <Wrapper data={data} bg="gray10" mt={[8, 9, 10, 11]}>
      <Box py={[8, 9, 10, 11]}>
        {data.title && (
            <Heading as="h2">{data.title}</Heading>
        )}
        {data.intro && (
            <Markdown>{data.intro}</Markdown>
        )}
        <Flex variant="grid" justifyContent="center">
        {data.items.map((item, i) => (
          <Box key={"item-"+i} width={["100%","50%",1/3]}>
            <Box pb={4}>
              <CustomImage img={item.image} />
              <Text
              sx={{
                fontWeight: "bold",
                fontSize: [5,5,6]
              }}>{item.title}</Text>
            </Box>
          </Box>
        ))}
        </Flex>
        {data.cta && (
          <Box pt={[6,6,11]}>
            <Link href={data.cta.lien}>
                <Button>{data.cta.label}<Fontello icon="arrow-right" /></Button>
            </Link>
          </Box>
        )}
      </Box>      
    </Wrapper>
  );
};

Features.propTypes = {
  data: PropTypes.shape({
    intro: PropTypes.any,
    items: PropTypes.array,
    title: PropTypes.any
  })
};
export default Features;

export const query = graphql`
  fragment FeaturesBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    intro
    items {
      title
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 200, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    cta {
      label
      lien
    }
  }
`;
