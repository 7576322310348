import PropTypes from "prop-types";
import React, { useState } from "react";
import Modal from "styled-react-modal";
import { Box, Button } from "rebass/styled-components";
import Fontello from "./Fontello";
import Container from "./Container";

const StyledModal = Modal.styled`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
`;

const ModalCustom = ({ children, onBackgroundClick, isOpen, ...props }) => {
  isOpen = isOpen || false;
  const [opacity, setOpacity] = useState(0);

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 10);
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0);
      setTimeout(resolve, 200);
    });
  }

  return (
    <StyledModal
      isOpen={isOpen}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      opacity={opacity}
      backgroundProps={{ opacity }}
      {...props}
    >
      <Container
      py={[2,2,3,4]}
      sx={{
        maxHeight: "100vh",
        overflowY: "scroll",
        "scrollbar-width": "none",
        "-ms-overflow-style": "none",
        "::-webkit-scrollbar": {display: "none"}
      }}>
        <Box
        bg="white"
        p={[5,6,7,8]}
        sx={{
          position: "relative"
        }}>
          <Button
            onClick={onBackgroundClick}
            variant="ninja"
            fontSize={6}
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              "i:before": {
                ml: "0",
                mr: "0"
              }
            }}
          >
            <Fontello icon="close" />
          </Button>
           {children}
        </Box>
      </Container>
    </StyledModal>
  );
};

ModalCustom.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  onBackgroundClick: PropTypes.any
};

export default ModalCustom;
